<template>
  <ion-page class="ion-page">
    <facility-form
      :loading="loading"
      :error="error"
      disabled="disabled"
      @submit="createFacility"
    />
  </ion-page>
</template>

<script>
  import { CREATE_FACILITY } from "@/graphql/mutations";
  import { logoutAndRedirect } from '@/utils/logoutAndRedirect';
  import { hasAuthError } from '@/utils/hasAuthError';
  import FacilityForm from "@/views/facilities/form/FacilityForm";
  import { ADD_FACILITY_TO_USER_MUTATION } from "@/store/store-mutations";
  import { STRIPE_CLIENT_ID, STRIPE_REDIRECT } from "@/configs";

  export default {
    components: {
      FacilityForm
    },
    data() {
      return {
        loading: false,
        error: null,
      }
    },
    mounted() {
      this.$on('picked-location', (location) => {
        this.location = location;
      })
    },
    methods: {
      createFacility(data) {
        this.loading = true
        this.error = null;

        this.$apollo.mutate({
          mutation: CREATE_FACILITY,
          variables: {
            data: {
              name: data.create.name,
              location: data.location,
              profile_image: data.file,
              website: data.create.website,
              email: data.create.email,
              phone: data.create.phone,
              type: data.create.type
            },
          },
        }).then((response) => {
          if(response.data && response.data.createFacility) {
            this.$store.commit(ADD_FACILITY_TO_USER_MUTATION, response.data.createFacility);
            window.location.replace(`https://connect.stripe.com/express/oauth/authorize?redirect_uri=${STRIPE_REDIRECT}&client_id=${STRIPE_CLIENT_ID}&stripe_user[business_type]=company&state=facilities/${response.data.createFacility.id}`);
          }
        }).catch(error => {
          if(hasAuthError(error)) {
            logoutAndRedirect()
          }

          this.error = error;
        })

        this.loading = false;
      }
    }
  }
</script>
